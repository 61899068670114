/* General and reset styles */
.App {
  text-align: center;
  background-color: black;
}

body, html {
  background-color: black;
  font-family: "League Spartan", system-ui, sans-serif;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.NavBar {
  background: #009997;
  align-items: right;
  display: flex;
  position: fixed; /* This will make it stick to the top */
  height: 1.5rem;
  max-height: 2rem;
  top: 0; /* This will position it at the top of the screen */
  width: 100%; /* This will make it span the full width of the viewport */
  z-index: 1; /* This will ensure it stays above other content */
}

.hamburger {
  margin: 0.25rem 0rem 2rem 0.25rem;
  justify-content: right;
}

@media screen and (max-width: 1000px) {
  .hamburger {
    margin: 0.25rem 0rem 2rem 0.25rem;
  }
}

.App-header {
  padding: 2.5rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
  font-family: monospace;
  background-color: black;
}

.App-link {
  color: #009997;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blinking-cursor {
  font-family: monospace;
  position: relative;
  animation: blinking-cursor 0.5s step-end infinite alternate;
}

@keyframes blinking-cursor {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.default {
  font-size: calc(14px + 2vmin);
  color: white;
  font-family: monospace;
  width: 75%;
}

.blog {
  font-size: 18px; /* Adjust font size for readability */
  line-height: 1.6; /* Improve line height */
  color: #E0E0E0; /* Adjust text color for better contrast */
  font-family: monospace; /* A more readable font for body text */
  max-width: 800px; /* Max width for content */
  margin: 0 auto; /* Center content */
  padding: 5px;
  text-align: left; /* Padding around content */
}

.blogtitle {
  color: white; 
  width: 85%;/* Distinct color for headings */
  font-family: monospace;
  padding: 2px;
  font-size: 22px;
}

.blogdate {
  color: rgb(188, 188, 188); 
  width: 90%;/* Distinct color for headings */
  font-family: monospace;
  padding: 2px;
  font-size: 20px;
}

.blog h2, .blog h3 {
  color: #009997; /* Distinct color for headings */
  font-family: monospace;
  padding: 5px;
}

.blog img {
  max-width: 100%; /* Responsive images */
  height: auto;
}

/* Styling for blockquotes */
.blog blockquote {
  font-style: italic;
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #333; /* Slight background for blockquotes */
  border-left: 4px solid #009997;
}

/* Styling for code */
.blog pre, .blogcode {
  background-color: #282c34;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  overflow-x: auto;
}

/* Styling for links */
.blog a {
  color: #009997;
  text-decoration: none;
}

.blog a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .blog {
    font-size: 16px; /* Smaller font size on smaller screens */
  }
}

.Box {
  padding: 1.5rem 1rem 1.5rem 1rem;
  border-color: #182523;
  border-radius: 20px;
  background-color: #171923;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  min-width: 50vw;
  min-height:35vh;
  font-size: calc(10px + 1vmin);
  color: #CBD5E0;
}


.Box ul {
  list-style-type: disc; 
  padding-left: 10px; 
}

.Box li {
  text-align: left;
  margin-left: 0; 
  color: white; 
}

.profile-photo {
  background: center / contain no-repeat url('./assets/carbon-twelve-7.png');
  border-radius: 50%;
  height: 20rem;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  width: 20rem;
  background-color: transparent;
  cursor: crosshair;
}

.button {
  background: #009997;
  background-color: #009997;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: calc(4px + 2vmin);
  font-family: sans-serif;
  color: white;
  border: 1px solid  #009997;
  margin: 2.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  cursor: pointer;
  transition-duration: 0.4s;
  font-weight: bolder;
  border-radius: 12px;

}

.button:hover {
  color: white;
  box-shadow: -0.25em 0.25em .15em #686869;
  
}

/* Globe container responsive adjustments */
.globe-container {
  position: relative;
  width: 100%; /* Use 100% width for full responsiveness */
  max-width: 1000px; /* Max width for larger screens */
  height: 75vh; /* Adjust height based on viewport height */
  border-color: #009997;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  margin: 0 auto;
}

.globe-holder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Ensure globe is centered within holder */
  align-items: center; 
  overflow: hidden;
}

/* Responsive design adjustments */
@media screen and (max-width: 768px) {
  .globe-container {
    height: 50vh; /* Reduce height on smaller screens */
  }

  .App-header {
    padding: 1rem;
    font-size: calc(6px + 2vmin);
  }

  .button {
    font-size: calc(3px + 2vmin); /* Adjust button font size */
    margin: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .globe-container {
    height: 45vh; /* Further reduce height for very small screens */
  }

  .App-header {
    padding: 0.5rem;
    font-size: calc(5px + 2vmin);
  }

  .button {
    font-size: calc(2px + 2vmin);
    margin: 0.5rem;
  }
}

.space {
  margin: 2rem auto;
}

blockquote {
  padding: 10px 20px;
  margin: 10px 0;
  border-left: 5px solid #009997;
  background-color: #f9f9f9;
  color: white; 
  font-style: italic;
}

.footer {
  font-family: monospace;
  font-weight: bold;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 7.5px;
  font-size: 1rem;
  background-color: black;
  color: #009997;
}
